import SSF from 'ssf';
import { commonStrings } from 'Constants/CommonStrings';

export const allowedCharacters = /^([^\W]|[ ()\-–‘'`&.,])*$/;
export const beginsOrEndsInSpaces = /(^[^ ](?=(.*(?=[^ ]$)|$)))|(^$)/;
export const noSpaces = /^[^\s]*$/;

export function getErrorsFromValidationError(validationError) {
  const FIRST_ERROR = 0;
  return validationError.inner.reduce((errors, error) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR],
    };
  }, {});
}

/* This provides the test object that should be passed to Yup mixed.test(options: object): Schema
it is not the test function provided to
mixed.test(name: string, message: string, test: function): Schema
*/
export function caseInsensitiveDuplicateTestOptions(currentNames, message) {
  return {
    name: 'caseInsensitiveDuplicateTest',
    message: message,
    test: (value) => {
      if (value) {
        const dupes = currentNames.filter(x =>
          x.toUpperCase() === value.toUpperCase());
        return dupes.length === 0;
      }
      // handle undefined
      return currentNames.filter(x => x === value).length === 0;
    },
  };
}

export function formatColumnByType({ format, value }, type) {
  if (!format && !value) {
    return '';
  }

  if (format) {
    try {
      if (value === undefined || value === null || value.length <= 0) {
        if (type === 'category') {
          return commonStrings.invalidCategories;
        }
        if (type === 'description') {
          return commonStrings.invalidDescription;
        }
      } else {
        return SSF.format(format, value);
      }
    } catch (e) {
      // Ignore catch
    }
  }

  return value;
}